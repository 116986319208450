// components/_news.scss
body > .news.-wrapper {
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

a.news {
  display: contents;
}

.news.-small {
  .news.-title {
    max-height: 5rem;
    overflow: hidden;
    display: flex;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      max-height: 5rem;
      height: fit-content;
      font-weight: bold;
    }
  }
}

.news.-small.-patrocinado {
  @media (max-width: 767px) {
    grid-template-columns: 1fr;

    .news.-area {
      order: 1;
    }

    .news.-img {
      order: 2;
      width: 100%;
      height: 100%;
    }

    .news.-title {
      order: 3;
    }
  }
}

.news {
  .-wrapper {
    padding-left: 16px;

    @media (max-width: 767px) {
      padding-left: 0px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-left: 0px;
    }
  }
  &.-grid {
    display: grid;
    gap: 33px;
    grid-template-columns: 2fr 1fr;
    margin: 0 auto;
    max-width: 85.375rem;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }
  }

  &.-subgrid {
    display: grid;
    gap: 24px 2em;
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "main main"
      "group2 group3"
      "group4 group3";
    // grid-template-rows: 0fr 1fr 1fr;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      grid-template-areas: "main" "group2" "group3" "group4";
      gap: 0;
      padding: 0 0.625rem;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  &.-sidebar {
    padding-right: 16px;

    @media (min-width: 1025px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap-reverse;

      .inner-sidebar {
        position: sticky;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        bottom: 30px;
        height: fit-content;
      }
    }

    @media (max-width: 767px) {
      padding-right: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-right: 0;
    }
  }

  &.-area {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.66;
    margin: 0 0 5px;
    text-transform: uppercase;
    display: flex;

    @media (max-width: 768px) {
      margin: 0 0 5px;
    }
  }

  &.-small {
    font-size: 1em;
    margin: 0;

    @media (max-width: 767px) {
      display: grid;
      column-gap: 0.625rem;
      grid-template-columns: 100px 1fr;
      grid-template-rows: auto 1fr;

      .-img {
        order: 1;
        display: block;
        object-fit: cover;
        object-position: 50%;
        margin-bottom: 0;
        width: 100px;
        height: 100px;
        grid-row: span 2;
      }

      .-area {
        order: 2;
        margin: 0.25em 0;
        overflow: hidden;
        max-height: 20px;
      }

      .-title {
        order: 3;
        font-size: 16px;
      }
    }
  }

  // @keyframes breakingnewsloop {
  //   from {
  //     left: 101%;
  //   }

  //   to {
  //     left: -105%;
  //   }
  // }

  // .hardnews.-wrapper .breakingnews {
  //   @media (max-width: 768px) {
  //     position: relative;
  //     max-width: 100%;
  //     height: 40px;
  //     white-space: nowrap;
  //     text-align: left;

  //     span {
  //       position: absolute;
  //       left: 10px;
  //       animation: breakingnewsloop 10s infinite ease-in-out;

  //       a {
  //         display: contents;
  //       }
  //     }
  //   }
  // }

  .hardnews.-wrapper .news.-group2 {
    .-medium {
      .news.-img {
        margin-bottom: 20px;
      }
    }
  }

  &.-medium {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 0.75em;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;

      .-img {
        order: 1;
        margin-bottom: 0;
      }
      .-area {
        order: 2;
      }
      .-title {
        order: 2;
        line-height: 1.43;
      }

      .-area,
      .-title {
        padding: 0 0.625rem;
      }
    }
  }

  &.-big {
    font-size: 2em;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0.5625em;

    @media (max-width: 768px) {
      font-size: 1.5em;

      .-img {
        margin-bottom: 10px;
      }
    }
  }

  &.-sub {
    position: relative;
    background: url("https://www.uai.com.br/imgs/uai/v1-1-4/bullet-black.svg") 0 0.25em no-repeat;
    font-size: 16px;
    margin: 0;
    padding-left: 1.5em;

    @media (min-width: 768px) {
      background-position: 0.5em 0.25em;
    }

    // & + .-small {
    //   @media (min-width: 768px) {
    //     padding: 0.75em 0 0;
    //   }
    // }

    @media (max-width: 767px) {
      background-position-x: 0px;
      margin: 0 0 1em;
      padding-left: 16px;
      font-weight: normal;

      & + & {
        background-position: 0px 1.25em;
        padding-top: 1em;

        &::before {
          position: absolute;
          display: block;
          content: "";
          width: calc(100% - 7px);
          height: 1px;
          top: 0;
          left: 7px;
          background: #c2d1d9;
        }
      }
    }

    .news.-title {
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &.-column2 {
    display: grid;
    gap: 0em 2em;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0 2em;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }

    .news.-title {
      font-size: 15px;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &.-column3 {
    display: grid;
    gap: 1em 2em;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }
  }

  &.-maingroup {
    grid-area: main;

    .-sub {
      margin-bottom: 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 0 0 20px;
    }
  }

  &.-group2 {
    grid-area: group2;

    .news.-big span.news.-title {
      font-size: 21px;
    }

    @media (min-width: 1025px) {
      border-bottom: 1px solid #c2d1d9;
      padding-bottom: 30px;

      .news.-small {
        margin-bottom: 10px;
      }

      .news.-sub {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 767px) {
      .-sub {
        margin: 0 0 1em;
      }
    }
  }

  &.-group3 {
    grid-area: group3;

    @media (max-width: 767px) {
      .-sub {
        border: 0;
        margin: 0 0 1.25em;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      display: grid;
      gap: 0 2rem;
      grid-template-columns: repeat(3, 1fr);

      .-small {
        padding-top: 0;

        &:nth-of-type(1) {
          order: 1;
        }

        &:nth-of-type(3) {
          order: 2;
        }

        &:nth-of-type(5) {
          order: 3;
        }
      }

      .-sub {
        &:nth-of-type(2) {
          order: 4;
        }

        &:nth-of-type(4) {
          order: 5;
        }

        &:nth-of-type(6) {
          order: 6;
        }

        &:nth-child(7),
        &:nth-child(8) {
          display: none;
        }
      }
    }
  }

  &.-group4 {
    grid-area: group4;

    @media (max-width: 767px) {
      margin-top: 1em;
    }
  }

  &.-img {
    display: block;
    margin-bottom: 4px;
    width: 100%;
  }
}

.sprite {
  &.-areas {
    display: inline-block;
    height: 1.67em;
    width: 1.67em;
    margin-right: 0.4em;
  }
}

.breakingnews {
  background: linear-gradient(to right, #f5515f 0%, #9f041b 100%);
  border-radius: 2px;
  color: #fff;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1.5em;
  padding: 0.75em 3em;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    padding: 0.75em 0.5em;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.hardnews,
.read-more {
  #taboola-below-article-thumbnails-homepage .trc_rbox_outer:before {
    padding: 0 0 10px 5px;
  }

  &.-bg {
    background: #eee;
    padding: 2em 0 1em;

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: 1025px) {
      padding: 30px 16px;

      border-bottom: 1px solid #c2d1d9;
    }
  }

  &.-news {
    max-height: 269px;

    .news.-img {
      max-height: 174px;
    }

    @extend .news.-small;

    @media (max-width: 767px) {
      margin: 0 0.625rem;
    }

    @media (min-width: 768px) {
      font-weight: 700;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      &:last-child {
        display: none;
      }
    }
  }

  &.-grid {
    display: grid;
    gap: 30px 2em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . ad1" ". . . ." "ad2 . . ." "ad2 . . .";
    margin: 0 auto;
    max-width: 85.375rem;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "." "." "." "ad1" "." "." "." "ad2" "." "." ".";
      gap: 1.25em;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: ". . ." ". . ." "ad1 ad1 ad1" ". . ." ". . ." "ad2 ad2 ad2";
      gap: 1.25em;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }
  }

  &.-ad1 {
    grid-area: ad1;
    // margin-top: 24px;
    // height: 283px;
    // margin: auto;

    // @media (max-width: 768px) {
    //   height: auto;
    //   max-width: 336px;
    // }
    // @media (max-width: 330px) {
    //   max-width: 300px;
    // }
  }

  &.-ad2 {
    grid-area: ad2;
    //   height: 600px;
    //   margin: auto;

    //   @media (max-width: 768px) {
    //     height: auto;
    //     max-width: 336px;
    //   }
    //   @media (max-width: 330px) {
    //     max-width: 300px;
    //   }
  }
}

.read-more {
  &.-wrapper {
    border-top: 1px solid #c2d1d9;
    border-bottom: none;
  }

  &.-wrapper > h2 {
    font-size: 32px;
    color: #1973b4;
    margin: 0 auto 30px;
    display: flex;
    justify-content: center;
  }

  .news.-small.ad {
    width: 300px;
    height: 250px;
    overflow: hidden;
  }

  figure {
    margin: 0;

    @media (max-width: 768px) {
      padding: 0 10px;
    }

    img.-img {
      width: 100%;
      max-width: 309px;
      height: 167px;

      @media (max-width: 768px) {
        max-width: 100%;
        object-fit: cover;
      }
    }

    p.-title {
      max-height: 5rem;
      overflow: hidden;
      display: flex;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.gastronomia,
.variedades,
.negocios {
  &.-wrapper {
    padding: 1.25em 0.625rem;

    @media (min-width: 992px) {
      margin: 0 auto 30px;
      max-width: 85.375rem;
      padding: 0px 16px;
    }
  }

  &.-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;

    @media (max-width: 768px) {
      position: relative;
    }
  }

  &.-title {
    font-size: 2rem;
    font-weight: 700;

    @media (max-width: 991px) {
      font-size: 1.375rem;
    }
  }

  &.-grid {
    display: grid;
    gap: 2em;

    @media (max-width: 767px) {
      gap: 1.25rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      gap: 1em;
    }
  }

  &.news {
    @media (min-width: 426px) {
      font-weight: 700;
    }
  }
}

.news.-subgrid {
  section.news.-group3 {
    .news.-small:first-of-type {
      padding: 0;

      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 20px 0 0;
      }
    }
  }

  section.news.-group4 {
    .news.-small {
      @media (min-width: 1025px) {
        padding: 0px 0 0;
      }
    }
  }

  .news.-small {
    @media (min-width: 1025px) {
      padding: 30px 0 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 20px 0 0;
    }

    @media (max-width: 767px) {
      padding: 0;
      & + article {
        margin-top: 20px;
      }
    }
  }
}

.gastronomia {
  &.-title,
  &.-area,
  &.-link {
    color: #e65245;
  }

  &.-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.-grid {
    grid-template-areas: ". . . ad1";
    grid-template-columns: 1fr 1fr 1fr 300px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "." "." "." "ad1";
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-areas: ". . ." "ad1 ad1 ad1";
    }
  }

  &.-ad1 {
    grid-area: ad1;

    .-img {
      // margin: 0 auto;
      // display: block;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1 / span 3;
    }
  }
}

.variedades {
  &.-title,
  &.-link {
    color: #ed7d00;
  }

  &.-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "." "taboola1" "." "taboola2" "." "taboola3" "." "taboola4";

      .taboola-1 {
        grid-area: taboola1;
      }

      .taboola-2 {
        grid-area: taboola2;
      }

      .taboola-3 {
        grid-area: taboola3;
      }

      .taboola-4 {
        grid-area: taboola4;
      }
    }
  }

  &.-source {
    font-size: 0.75rem;
    margin: 0.25em 0;

    a {
      color: #9b9b9b;
    }

    @media (max-width: 767px) {
      order: 4;
    }
  }

  &.-attribution {
    font-size: 0.6875rem;
    font-weight: 400;

    @media (min-width: 426px) {
      text-align: right;
    }

    a {
      color: #2c322c;
    }

    @media (max-width: 767px) {
      order: 5;
    }

    .-area {
      margin: 0.25em 0 0.75em;
    }
  }

  &.-taboola {
    @media (max-width: 767px) {
      // grid-template-rows: auto 1fr min-content min-content min-content;

      .news {
        &.-img {
          grid-row: span 4;
        }
      }
    }
  }
}

.negocios {
  &.-title,
  &.-link {
    color: #1973b4;
  }

  &.-grid {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "." "." "." ".";
    }
  }

  .news {
    @media (min-width: 768px) and (max-width: 1024px) {
      &:nth-child(4) {
        display: none;
      }
    }
  }
}

section.news.-group3 .news.-sub {
  margin-top: 10px;
}

.hardnews.-wrapper .news .-sub a:hover {
  .-title {
    opacity: 0.8;
  }
}

.hardnews.-wrapper .news.-group2 {
  .-medium {
    .-area,
    .-title {
      padding-left: 20px;
    }

    .-title {
      display: flex;
    }
  }

  .news.-sub {
    margin-left: 20px;

    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
}

.esportes {
  &.-title,
  .-link {
    color: #00660d;
  }
}

.entretenimento {
  &.-title,
  .-link {
    color: #ed7d00;
  }
}

.noticias {
  &.-title {
    color: #1c78bb;
  }

  a[href*="betnabola"] .-area {
    color: #1c78bb;
  }
}

.section.-blogs {
  .-area {
    color: #8b572a;
  }

  .section.-header a[href*="uai.com.br/blogs"] {
    color: #8b572a;
  }
}

article.hardnews.-news[data-item="bloco-2-10"],
article.hardnews.-news[data-item="bloco-2-11"],
article.hardnews.-news[data-item="bloco-2-12"] {
  @media (min-width: 1121px) {
    margin-top: -45px;
  }
}
