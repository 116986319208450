.destaques {
  background-color: #f5f5f5;
  margin-bottom: 30px;

  .wrapper {
    margin: 0 auto;
    max-width: 85.375rem;
    position: relative;

    padding: 30px 16px 0;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    @media (max-width: 768px) {
      display: block;
    }

    .destaques-section {
      position: relative;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        // margin: 0 auto;
        max-width: 100%;
        padding-bottom: 34px;
      }
    }

    @media (min-width: 1279px) {
      height: 330px;
    }

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;

      .ads {
        display: none;
      }

      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 20px;
    }

    .veiculos,
    .imoveis,
    .classificados {
      display: flex;
    }

    .d-none {
      display: none;
    }

    .destaques__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 990px;

      @media (max-width: 768) {
        max-width: 100%;
      }

      .buttons {
        display: flex;

        button + button {
          margin-left: 30px;
        }

        @media (max-width: 768px) {
          width: 100%;

          button + button {
            margin-left: 0px;
          }
        }

        button {
          outline: 0;
          padding: 0;
          width: 165px;
          height: 40px;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
          font-size: 12px;
          font-weight: bold;
          line-height: normal;
          color: #333;
          text-align: center;
          background-color: transparent;
          text-transform: uppercase;

          @media (max-width: 767px) {
            width: 100%;
          }
        }

        .btn__veiculos[active] {
          background-color: #b52614;
          color: #fff;
        }
        .btn__imoveis[active] {
          background-color: #6a8f21;
          color: #fff;
        }
        .btn__classificados[active] {
          background-color: #1c78bb;
          color: #fff;
        }
      }

      .text-logo {
        div {
          a {
            display: flex;
            align-items: center;
          }
        }

        .veiculos a {
          // align-items: baseline;
        }

        span {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          line-height: normal;
          color: #333;

          @media (max-width: 767px) {
            margin: 0;
          }
        }

        img {
          margin-left: 10px;
          max-width: 197px;
          max-height: 30px;

          @media (max-width: 767px) {
            display: none;
          }
        }

        @media (max-width: 767px) {
          position: absolute;
          bottom: 0;
        }
      }
    }

    .destaques__body {
      display: flex;
      max-width: 990px;
      margin-top: 18px;

      @media (max-width: 767px) {
        div {
          flex-direction: column;
          width: 100%;
        }
      }

      figure {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;

        & + figure {
          margin-left: 30px;
        }

        a {
          display: contents;
        }

        img {
          width: 309px;
          height: 167px;
          object-fit: cover;
        }

        figcaption {
          display: flex;
          flex-direction: column;

          strong {
            font-size: 18px;
            color: #333;
            font-weight: bold;
            line-height: normal;
            margin-top: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 309px;

            @media (max-width: 768px) {
              white-space: normal;
            }
          }

          span {
            margin: 5px 0 0;
            font-size: 16px;
            color: #333;
            line-height: normal;
            text-transform: uppercase;
          }
        }

        @media (max-width: 767px) {
          flex-direction: row;

          & + figure {
            margin-left: 0px;
            margin-top: 20px;
          }

          img {
            width: 100%;
            max-width: 100px;
            height: 100px;
          }

          figcaption {
            margin-left: 10px;
            justify-content: center;
          }
        }
      }
    }

    .ad {
      margin: 0;
      max-width: 300px;

      @media (min-width: 768px) and (max-width: 1024px) {
        margin: 20px auto 0;
      }

      @media (max-width: 768px) {
        margin: 20px auto 0;
        margin-bottom: 20px;
        max-width: 336px;
      }
    
      @media (max-width: 330px) {
        max-width: 300px;
      }
    }
  }
}
