.ad,
.-ad {
  background: url("https://www.uai.com.br/imgs/uai/v1-1-4/publicity.png");
  text-align: center;
  width: 100%;

  @media (min-width: 992px) {
    margin: auto;
    max-width: 85.375rem;
  }

  &[data-section*="publicidade-rasgado"] {
    margin: 0px auto 30px;
  }
}

.taboola {
  padding: 0 10px;

  div {
    margin: 0 !important;
  }
}

[data-ads*="uai-publicidade-retangulo"] {
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}