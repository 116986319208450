// components/_especiais.scss
#especiais {
  display: flex;
  overflow: hidden;
}

.especiais {
  position: relative;
  width: 100%;
  background-color: #333;
  padding-bottom: 70px;
  max-height: 435px;
  overflow: hidden;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-height: 530px;
  }

  @media (max-width: 767px) {
    padding-bottom: 30px;
    margin-top: 0;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px;

    h4,
    span {
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }

    a {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  }

  &-body {
    display: flex;

    .tns-outer {
      overflow: hidden;
    }

    .tns-outer .tns-nav {
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;

      button {
        width: 10px;
        height: 10px;
        background-color: #333;
        border-radius: 50%;
        border: 2px solid #c2d1d9;
      }

      button.tns-nav-active {
        background-color: #c2d1d9;
      }
    }

    figure {
      margin: 0;
      display: flex;
      flex-direction: column;

      img {
        display: flex;
        width: 100%;
        height: 238px;
        object-fit: cover;
        margin: 0 auto;

        @media (min-width: 768px) and (max-width: 1024px) {
          height: 339px;
        }
      }

      figcaption {
        display: flex;

        p {
          margin: 20px 20px 0;
          font-size: 15px;
          color: #fff;
        }
      }
    }
  }
}

.sprite {
  &.-right {
    height: 1em;
    fill: #fff;
    width: 1em;
    margin: 0 0 4px 3px;
  }
}
