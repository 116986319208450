.vrum {
  margin: 0 auto 30px;
  max-width: 85.375rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &.-overlay {
    margin-bottom: 5px;
  }

  .wrapper {
    margin: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 16px 20px;

    @media (max-width: 767px) {
      position: relative;
      align-self: flex-start;
      margin: 0;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 15px;

      .-menu {
        right: 10px;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 0 10px 20px;
    }

    a {
      display: flex;
      align-items: baseline;
      text-decoration: none;
    }

    a h2 {
      font-size: 32px;
      font-weight: bold;
      color: #b52614;
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        font-size: 21px;
      }
    }

    a img {
      &.mobile {
        display: none;
      }

      @media (max-width: 767px) {
        height: 20px;
        width: 20px;
        object-fit: contain;

        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }

    &.avaliacao {
      margin-top: 10px;

      @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 15px;
      }

      a h2 {
        font-size: 21px;
        font-weight: bold;
        color: #333;
      }
    }

    .options {
      display: flex;

      @media (max-width: 767px) {
        display: none;
      }

      a {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
      }

      a + a {
        margin-left: 20px;
      }
    }
  }

  &.avaliacao {
    a > span {
      display: none;
    }
  }

  &.-grid {
    padding: 0 16px;
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . ad1";
    margin: 0 auto;
    max-width: 85.375rem;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "." "." "." "ad1";
      gap: 1.25em;
      margin: 0 0;
      padding: 0 10px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas: ". . .";
      gap: 2em;
      padding: 0 10px;
    }

    // "." "." "." "." "ad2"

    span {
      color: #b52614;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      @media (max-width: 767px) {
        float: left;
        margin-bottom: -15px;
        margin-left: 110px;
      }
    }

    a {
      @media (min-width: 768px) and (max-width: 1024px) {
        &:nth-child(4) {
          display: none;
        }
      }
    }

    figure {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        flex-direction: row;
      }

      & + figure {
        margin-left: 30px;
      }

      a {
        display: contents;
      }

      img {
        width: 309px;
        height: 167px;
        object-fit: cover;

        @media (max-width: 767px) {
          width: 100px;
          height: 100px;
          margin-right: 10px;
        }
      }

      figcaption {
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
          padding-top: 19px;
        }

        p {
          margin: 5px 0;
          font-weight: bold;
          font-size: 16px;

          @media (max-width: 768px) {
            font-weight: 300;
          }
        }
      }
    }

    &.avaliacao {
      figure {
        position: relative;

        .-overlay::before {
          content: "";
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .-overlay::after {
          content: "";
          background-image: url("https://www.uai.com.br/imgs/uai/v1-1-4/play.svg");
          background-size: cover;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 75px;
          height: 75px;
          z-index: 2;

          @media (max-width: 767px) {
            width: 29px;
            height: 29px;
          }
        }

        .-overlay {
          width: 100%;
          height: 157px;

          @media (max-width: 767px) {
            max-width: 100px;
            height: 71px;
          }
        }
      }
      figure img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      figcaption {
        @media (max-width: 767px) {
          margin-left: 10px;
          padding: 0;
        }

        label {
          font-size: 12px;
          font-weight: bold;
          color: #b52614;
        }

        p {
          font-weight: normal;

          @media (max-width: 768px) {
            font-weight: bold;
            margin: 0;
          }
        }
      }
    }
  }

  &.-ad1 {
    grid-area: ad1;
  }
  &.-ad2 {
    grid-area: ad2;
  }
}
