// components/_regions.scss

.region {
  &.-wrapper {
    padding: 0;
    margin: 0 auto;
    max-width: 85.375rem;

    @media (max-width: 767px) {
      padding: 2em 0.625rem;
    }

    .section.-title {
      font-size: 1.8rem;
    }

    .section.-header {
      grid-template-columns: 50% 50%;

      .custom-select {
        justify-self: flex-end;
      }

      @media (max-width: 767px) {
        grid-template-columns: 100%;

        .custom-select {
          justify-self: flex-start;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &.section.-header,
    &.-title {
      margin-bottom: 1rem;
    }
  }

  &.-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }

    .news.-small {
      max-height: 100px;
    }
  }

  &.-img {
    float: left;
    margin: 0 0.8em 0 0;
  }

  a.news .-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }

  .-area {
    @media (max-width: 767px) {
      margin: 0 0 0.5em;
    }
  }
}
