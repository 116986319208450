footer {
  display: flex;
  flex-direction: column;
  background-color: #eee;
}

footer .menu-principal {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 30px 16px 20px;
  max-height: 340px;
  box-sizing: border-box;
  max-width: 85.375rem;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 767px) {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mt-22 {
    margin-top: 22px;
  }

  .mt-18 {
    margin-top: 18px;
  }

  .mt-9 {
    margin-top: 9px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      text-transform: uppercase;
      margin: 0 0 10px;
    }

    li {
      font-size: 14px;
      color: #333;
      line-height: 1.5;

      a {
        font-weight: 400;
      }
    }
  }
}

footer .institucional {
  background-color: #1973b4;
  padding: 20px 16px;
  height: 60px;

  @media (max-width: 767px) {
    justify-content: center;
    padding: 14px 16px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    // padding: 14px 10px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 85.375rem;
    width: 100%;
    margin: 0 auto;

    ul {
      @media (max-width: 767px) {
        display: none;
      }

      @media (max-width: 1024px) {
        display: none;
      }

      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      li {
        line-height: 1.5;

        & + li {
          margin-left: 20px;
        }
      }

      li a {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
    }
    div {
      display: flex;
      align-items: center;
      text-align: center;

      span {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
