.entretenimento {
  &.-grid2 {
    @media (max-width: 767px) {
      padding-top: 0;
    }
    @media (min-width: 768px) {
      display: grid;
      gap: 0 2rem;
      grid-template-columns: 423px auto;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.cinema {
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.cinema.section.-header {
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}

.cartaz {
  margin: 0;

  img {
    width: 100%;

    @media (min-width: 768px) {
      max-height: 300px;
      object-fit: cover;
    }
  }
}

.cinema {
  &.filters {
    display: flex;
    gap: 0.625rem;

    @media (max-width: 1024px) {
      display: none;
    }

    @media (min-width: 1025px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: repeat(2, 1fr);
      gap: 0.5rem;

      select[name="cinema_room"] {
        // grid-area: 1 / 1 / 2 / 2;
      }

      select[name="movie"] {
        // grid-area: 2 / 1 / 3 / 2;
      }

      button {
        // grid-area: 1 / 2 / 3 / 3;
        padding: 0.8rem 0.5rem;
        max-width: 150px;
      }
    }
  }

  .tns-outer {
    @media (max-width: 767px) {
      margin: 0 -0.875rem;
    }
  }

  &.-grid {
    @media (min-width: 768px) {
      overflow: hidden;
    }
  }
}

.cinema,
.promocoes {
  .news.-title {
    @media (min-width: 768px) {
      font-weight: 700;
    }
  }
}
