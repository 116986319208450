.blogs {
  background-color: #eee;
  margin-bottom: 30px;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 16px;
    margin: 0 auto;
    max-width: 85.375rem;

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 15px 10px 30px;

      .ads {
        display: none;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-left: 10px;
      padding-right: 10px;
      flex-wrap: wrap;
    }

    .body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      width: 100%;
      max-width: 990px;

      @media (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        max-width: 100%;
      }

      @media (min-width: 1025px) and (max-width: 1309px) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px 10px;
        max-width: 70%;
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 20px;

        figure:nth-child(4),
        figure:nth-child(5),
        figure:nth-child(6) {
          display: none;
        }
      }

      figure {
        margin: 0;
        display: flex;

        a {
          display: contents;
        }

        img {
          width: 100px;
          height: 100px;
          border-radius: 2px;
          object-fit: cover;
        }

        figcaption {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          span {
            font-size: 12px;
            color: #1c78bb;
            font-weight: bold;
            text-transform: uppercase;
          }

          p {
            margin: 7px 0 0;
            font-size: 16px;
            color: #333;
            line-height: normal;
          }
        }
      }
    }

    .ad {
      margin: 0;
      max-width: 300px;

      @media (min-width: 768px) and (max-width: 1309px) {
        margin: 20px auto 0;
      }

      @media (max-width: 767px) {
        margin: 20px auto 0;
      }
    }
  }
}
