main.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  margin: 0 auto;
  max-width: 85.375rem;
  padding: 0 10px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
  }

  figure {
    display: flex;

    @media (max-width: 767px) {
      margin: 1.25rem 0;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 2px;
      margin-right: 10px;
      object-fit: cover;
    }

    figcaption {
      display: flex;
      flex-direction: column;

      span {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      p {
        margin: 7px 0 0;
        font-size: 16px;
        font-weight: 300;
        color: #333;
      }
    }
  }

  .content {
    grid-area: 1 / 2 / 2 / 9;

    @media (max-width: 767px) {
      grid-area: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .search {
      width: 100%;
      display: flex;
      margin-bottom: 30px;

      input,
      button,
      div.custom-select {
        width: 100%;
        height: 40px;
        border: 1px solid #c2d1d9;
        border-radius: 2px;
        color: #333;
        font-weight: bold;
        line-height: 1.29;
        font-size: 14px;
      }

      input {
        max-width: 437px;
        padding-left: 10px;
      }

      input.custom-option__input {
        width: 0;
        height: 0;
      }

      div.custom-select {
        max-width: 187px;
        border: 0;
        cursor: pointer;

        label {
          border-color: #c2d1d9;
        }

        @media (max-width: 410px) {
          display: none;
        }
      }

      button {
        cursor: pointer;
        max-width: 146px;
        background-color: #1973b4;
        color: #fff;
        font-size: 12px;
        border: none;
        text-transform: uppercase;

        @media (max-width: 767px) {
          max-width: 110px;
        }
      }
    }

    .search-results {
      li + li {
        margin-top: 30px;
      }

      figure {
        margin: 0;

        img {
          width: 195px;
          height: 110px;
          margin-right: 16px;

          @media (max-width: 414px) {
            width: 165px;
            height: 93.08px;
          }
        }

        img + figcaption p {
          max-width: 440px;
        }

        figcaption p {
          width: 100%;
          font-weight: normal;

          &:hover {
            color: #333c;
          }
        }
      }

      > div {
        width: 100%;
      }

      button {
        display: block;
        margin: 30px auto;
        width: 100%;
        max-width: 240px;
        height: 40px;

        font-size: 12px;
        background-color: #1973b4;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  aside {
    grid-area: 1 / 10 / 2 / 13;

    @media (max-width: 767px) {
      grid-area: auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .mais-lidas h2 {
      font-size: 21px;
      color: #185a9d;
      margin: 0 0 12px;
    }

    .mais-lidas ul {
      figure {
        margin: 0.5rem 0;

        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
