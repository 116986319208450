/**
 * generic/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

// @import "";

.arrow {
  height: 20px;
  width: 20px;
  padding: 12px;
  margin-left: 4px;
  background-position: center;

  &.-right {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-1-4/arrow-right.svg");
  }

  &.-gray {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-1-4/arrow-gray.svg");
  }
}
