.conteudo-premium {
  background-color: rgba(194, 209, 217, 0.7);
  padding: 30px 43px;
  margin-top: 30px;

  .parceiros-body {
    display: flex;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    padding: 30px 20px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 20px auto 0;
  }

  @media (max-width: 767px) {
    padding: 10px 10px 27px;
    margin-top: 20px;
  }

  article figure {
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;

      .news.-img {
        margin-bottom: 10px;
      }
    }

    img {
      margin-right: 32px;
      max-width: 380px;

      @media (min-width: 1025px) and (max-width: 1280px) {
        margin-right: 16px;
      }

      @media (max-width: 767px) {
        margin-right: 0;
        max-width: 100%;
      }
    }

    figcaption {
      .news.-area {
        color: #1c78bb;
      }

      .news.-title {
        font-size: 21px;
        line-height: 1.43;
        font-weight: bold;
      }

      .news.-description {
        font-weight: normal;
      }
    }
  }
}
