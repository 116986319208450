.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blogs-btn {
  position: relative;
  width: 40px;
  height: 21px;
  border-radius: 100px;
  overflow: hidden;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #c2d1d9;
  transition: 0.3s ease all;
  z-index: 1;
}

.blogs-btn .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 13px;
  height: 13px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 1;

  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.blogs-btn .checkbox:checked + .knobs:before {
  content: "";
  left: 22px;
  background-color: #fff;
}

.blogs-btn .checkbox:checked ~ .layer {
  background-color: #333;
}

.blogs-btn .knobs,
.blogs-btn .knobs:before,
.blogs-btn .layer {
  transition: 0.3s ease all;
}
.favoritos.-wrapper {
  margin: 0;
  display: flex;
  width: 100%;

  @media (min-width: 1025px) and (max-width: 1280px) {
    // max-width: 50%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 1024px;
  }

  @media (max-width: 767px) {
    // padding: 0 10px;
    margin: 0;
    max-width: 100%;
  }
  // @media (max-width: 435px) {
  //   max-width: 435px;
  // }
  // @media (max-width: 375px) {
  //   max-width: 375px;
  // }
  // @media (max-width: 320px) {
  //   max-width: 320px;
  // }
}

section.favoritos {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 675px;
  height: 100%;
  max-height: 387px;
  overflow: hidden;

  @media (min-width: 1025px) and (max-width: 1280px) {
    max-width: 620px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 0 2rem;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    min-height: 378px;
    padding: 0;
  }

  .standart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    background: linear-gradient(
      176deg,
      rgba(245, 245, 245, 1) 0%,
      rgba(225, 225, 225, 1) 100%
    );

    div {
      height: 100%;
      width: 100%;
      background: url("https://www.uai.com.br/imgs/uai/v1-1-4/rss.svg");
      background-repeat: no-repeat;
      background-position: 10px 45px;

      min-height: 277px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        padding: 0 10px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        background-position: 10px 125px;
      }
    }

    h2 {
      font-size: 32px;
      font-weight: bold;
      color: #333;
      margin: 60px 0 0;

      @media (min-width: 767px) and (max-width: 1024px) {
        margin: 0;
      }

      @media (max-width: 768px) {
        margin: 0;
      }
    }

    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.67;
      color: #333333;
      margin: 10px auto 30px;
      max-width: 424px;
      width: 100%;
    }

    button {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      background-color: #1973b4;
      cursor: pointer;
      max-width: 196px;
      width: 100%;
    }
  }

  .-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    margin-bottom: 1.85rem;

    @media (max-width: 767px) {
      padding-left: 10px;
    }

    h2 {
      margin: 0;
      font-size: 1.8rem;

      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 21px;
      }
    }

    button.desktop {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      background: none;
      padding: 5px 2px;
      height: fit-content;
      border: 0;

      @media (max-width: 768px) {
        display: none;
      }
    }

    button.mobile {
      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  .canais {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    #tns1-mw .tns-nav,
    #tns2-mw .tns-nav,
    #tns3-mw .tns-nav,
    #tns4-mw .tns-nav,
    #tns5-mw .tns-nav,
    #tns6-mw .tns-nav,
    #tns7-mw .tns-nav,
    #tns8-mw .tns-nav,
    #tns9-mw .tns-nav,
    #tns10-mw .tns-nav {
      display: none;
    }

    div.tns-ovh .tns-nav {
      display: none;
    }

    @media (min-width: 421px) and (max-width: 540px) {
      max-width: 540px;
    }

    @media (min-width: 376px) and (max-width: 420px) {
      max-width: 420px;
    }

    @media (min-width: 321px) and (max-width: 375px) {
      max-width: 375px;
    }

    @media (max-width: 320px) {
      max-width: 320px;
    }

    .tns-outer {
      margin-bottom: 0;
    }
  }

  .favoritados {
    display: flex;
    position: relative;

    a i.icone-site {
      display: block;
      padding-left: 1.5rem;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 1.67em;
      min-height: 1.67em;
      overflow: hidden;

      color: #8b572a;
      background-image: url("https://www.uai.com.br/imgs/uai/v1-1-4/areas/parceiro.svg");
    }

    a.card-favorito.visible:nth-child(n + 4) {
      display: none;
    }

    a.card-favorito {
      padding-right: 30px;

      @media (min-width: 768px) and (max-width: 1024px) {
        padding-right: 20px;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        color: #8b572a;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      figure {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-width: 196px;

        img {
          margin: 5px 0 0;
          width: 196px;
          height: 110px;
        }

        figcaption {
          margin: 5px 0 0;
          p {
            font-size: 16px;
            font-weight: 300;
            color: #333;
            max-height: 4rem;
            overflow: hidden;
          }
        }
      }
    }
  }

  .modal_blogs {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    right: 0;
    height: 100%;
    z-index: 10;

    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    background: linear-gradient(
      176deg,
      rgba(245, 245, 245, 1) 0%,
      rgba(225, 225, 225, 1) 100%
    );

    & > div {
      position: relative;
      margin: 0 auto;
      padding: 10px;
      width: 100%;
      height: 100%;
      max-height: 300px;
      background: url("https://www.uai.com.br/imgs/uai/v1-1-4/rss.svg");
      background-repeat: no-repeat;
      background-position: 10px 45px;

      @media (min-width: 768px) and (max-width: 1024px) {
        background-position: 10px 125px;
        max-height: 95%;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      max-width: 80%;
      height: 100%;
      max-height: 40px;

      @media (max-width: 768px) {
        margin: 0;
        max-width: 63%;
      }

      span {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
      }

      button {
        position: absolute;
        top: 10px;
        right: 30px;

        border: 0;
        cursor: pointer;
        background-color: #1973b4;
        color: #f5f5f5;
        border-radius: 4px;

        text-transform: uppercase;
        padding: 4px 10px;
        font-family: Roboto;
        height: 100%;
        max-height: 40px;
        width: 100%;
        max-width: 93px;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

        @media (max-width: 767px) {
          height: 270px;
          max-width: 83px;
          right: 12px;
        }
      }
    }

    .content {
      margin: 13px 10px 0 15px;
      height: 240px;

      @media (max-width: 767px) {
        height: 270px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        height: 90%;
      }

      ul {
        list-style: none;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 0 35px;
        margin: 0;
        padding: 0;
        max-height: 95%;
        overflow-x: hidden;
        overflow-y: scroll;

        @media (min-width: 768px) and (max-width: 1024px) {
          max-height: 90%;
        }

        @media (max-width: 767px) {
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            width: 70%;
          }
        }

        .checkbox input {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        .checkbox label {
          position: relative;
          width: 100%;
          display: inline-block;
          cursor: pointer;
        }
        .checkbox label::before {
          content: "";
          display: inline-block;

          height: 1.5em;
          width: 1.5em;
          background: #fff;
          margin-right: 0.5em;
          border: 1px solid #333;
          border-radius: 50%;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
          transition: all 0.2s ease;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
        .checkbox label::after {
          content: "\2713";
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
          font-size: 1em;
          color: #fff;
          opacity: 0;
          right: 0.75em;
          transition: all 0.2s ease;
        }
        .checkbox label:focus::before,
        .checkbox label:hover::before {
          background: #ddd;
        }
        .checkbox input:checked + label::before {
          background: #333333;
          border-color: #333333;
        }
        .checkbox input:checked + label::after {
          opacity: 1;
        }

        li {
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        }

        li label {
          font-size: 15px;
          font-weight: 300;
          line-height: 2.27;
          color: #333333;
        }
      }
    }
  }
}
